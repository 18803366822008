































































































































































































































































































































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import {ApexOptions} from 'apexcharts'
import {mixins} from 'vue-class-component'
import LoadingMixin from '~/mixins/loading-mixin'
import {Course, CourseSeries} from '~/components/data-class/data-class'
import moment from 'moment'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import {toInstanceForceArray} from '~/utils/Serializer'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {Dict} from '~/utils/misc'


function sortedObj<T>(raw: Dict<T>): Dict<T> {
    return Object.keys(raw).sort().reduce((obj, key) => {
        obj[key] = raw[key]
        return obj
    }, {})
}

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Tutor Performance'
        }
    }
})
export default class TutorDashboard extends mixins(QueryParserMixin, LoadingMixin, DatePickerShortcutMixin) {
    static permission = ReportPermission.TutorPerformance

    queryDef = [
        {localVar: 'startDate', queryStr: 'start', type: QueryType.Number},
        {localVar: 'endDate', queryStr: 'end', type: QueryType.Number},
        {localVar: 'selectedInstructor', queryStr: 'tutor'},
        {localVar: 'acquisitionSelectedSeries', queryStr: 'a-series', type: QueryType.Number},
        {localVar: 'retentionSelectedSeriesId', queryStr: 'r-series', type: QueryType.Number},
        {localVar: 'trialClassSelectedCourse', queryStr: 't-course'},
        {localVar: 'trialClassVersion', queryStr: 't-course-v', type: QueryType.Number},
        {localVar: 'userProfileSelectedCourse', queryStr: 'u-course'},
        {localVar: 'refundSelectedCourse', queryStr: 'r-course'},
    ]

    // region start - end range
    dateRange: number[] = []
    startDate = 0
    endDate = 0

    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    // endregion

    // region tutor list
    selectedInstructor = ''
    // endregion

    // region overview
    overview = {
        revenue: {
            paid: 0,
            trial: 0,
            paid_deduct_diamond: 0,
            trial_deduct_diamond: 0,
            paid_refunded: 0,
            trial_refunded: 0,
        },
        students: {
            paid: 0,
            trial: 0,
            paid_unique: 0,
            trial_unique: 0
        }
    }
    // endregion

    seriesList: CourseSeries[] = []

    // region Acquisition
    acquisitionType = false
    acquisitionSelectedSeries = -1

    acquisitionTableData: {
        tag: string,
        timestamp: number,
        trial: number,
        trial_count: number,
        organic: number,
        organic_count: number
    }[] = []

    acquisitionTitle() {
        return this.acquisitionTableData.map((s) => s.tag)
    }

    acquisitionTrial() {
        return this.acquisitionTableData.map((s) => (!this.acquisitionType ? s.trial_count : s.trial))
    }

    acquisitionOrganic() {
        return this.acquisitionTableData.map((s) => (!this.acquisitionType ? s.organic_count : s.organic))
    }

    acquisitionTotal() {
        return this.acquisitionTableData.map((s) => (!this.acquisitionType ? (s.trial_count + s.organic_count) : (s.trial + s.organic)))
    }

    acquisitionSeries() {
        return [
            {
                name: 'Trial',
                data: this.acquisitionTrial()
            },
            {
                name: 'Organic',
                data: this.acquisitionOrganic()
            }
        ]
    }

    acquisitionConfig() {
        let config: ApexOptions = {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: this.acquisitionTitle(),
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return this.acquisitionType ? '$' + value : String(value)
                    },
                    minWidth: 70,
                    maxWidth: 70,
                },
            },
            fill: {
                opacity: 1
            },
            grid: {
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 8
                },
            }
        }

        return config
    }

    // endregion

    //region Trial Class Performance
    trialClassSourceType = false
    trialClassSelectedCourse = ''
    rawTrialClassTableData: Array<{
        id: number,
        date: string,
        time: string,
        applicants: number,
        attendance: number,
        conversions_applied: number,
        conversionAPercentage: string
        conversions_paid: number,
        conversionPPercentage: string,
        applied_c_courses: Dict<number>,
        paid_c_courses: Dict<number>,
    }> = []
    trialClassVersions: { [key: number]: number[] } = {}
    trialClassVersion: number | '' = ''
    // endregion

    // region User Profile
    userProfileSelectedCourse = ''
    userProfileDseYrData: number[] = []
    userProfileDseYrConfig = this.defaultBarChartConfig()
    userProfileBandingData: number[] = []
    userProfileBandingConfig = this.defaultBarChartConfig()
    // endregion

    // region Refund Statistics
    refundSelectedCourse = ''
    refundRequests = 0
    refundTotal = 0
    refundTableData: Array<{ date: string, member_id: string, name: string, courseIdName: string, amount: string }> = []
    // endregion

    // region paid course series retention
    retentionSwitch = false
    retentionSelectedSeriesId = -1

    get retentionSelectedSeries(): CourseSeries | undefined {
        return this.seriesList.find((s) => s._id === this.retentionSelectedSeriesId)
    }

    get retentionVersionOptions(): number[] {
        const selectedSeries = this.retentionSelectedSeries
        if (selectedSeries) {
            const courses = this.courses.filter((c) => selectedSeries._id === c.series_id)
            if (courses.length) {
                const highestVersion = courses.map((c) => c.current_version).sort((a, b) => a - b).last()
                if (highestVersion !== 1) {
                    return Array(highestVersion).fill(0).map((_, index) => index + 1)
                }
            }
        }
        return []
    }

    mounted() {
        window['xx'] = () => {
            console.log(this.trialClassSourceType)
        }
    }

    retentionSelectedVersion: number = 1
    retentionMaxNumber = 1
    retentionTableData: Array<{ course_id: string, total: number, retention: number[] }> = []
    // endregion

    // region referral
    seriesReferralSelectedIndex = 0
    seriesReferralOptions: string[] = []
    seriesReferralTableData: Array<{ label: string, data: { item: string, count: number }[] }>
        = [{label: 'All', data: [{item: 'total', count: 0}]}]

    get seriesReferralTable() {
        return this.seriesReferralTableData[this.seriesReferralSelectedIndex].data
    }

    trialReferralSelectedIndex = 0
    trialReferralOptions: string[] = []
    trialReferralTableData: Array<{ label: string, data: { item: string, count: number }[] }>
        = [{label: 'All', data: [{item: 'total', count: 0}]}]

    get trialReferralTable() {
        return this.trialReferralTableData[this.trialReferralSelectedIndex].data
    }

    // endregion

    // region default config
    defaultPieChartConfig(): ApexOptions {
        return {
            labels: []
        }
    }

    defaultBarChartConfig(): ApexOptions {
        return {
            chart: {
                type: 'bar',
                height: 'auto'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '20%',
                    barHeight: '20%'
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                min: 6,
                max: 6,
                categories: []
            }
        }

    }

    // endregion

    // Courses of the selected tutor. For dropdown options.
    courses: Course[] = []
    trialCourses: Course[] = []

    async created() {
        this.parseQuery()

        const date = new Date()
        let start = new Date(date.getFullYear(), date.getMonth(), 1)
        let end = new Date(date.getFullYear(), date.getMonth() + 1, 0)

        if (!this.startDate) {
            this.startDate = start.getTime()
        }

        if (!this.endDate) {
            this.endDate = end.getTime() + 24 * 60 * 60 * 1000 - 1
        }

        this.dateRange[0] = this.startDate
        this.dateRange[1] = this.endDate

        await this.getSeries()
        await this.getCourseDashboard()
    }

    async changedDatePicker() {
        this.startDate = this.dateRange[0]
        this.endDate = this.dateRange[1] + 24 * 60 * 60 * 1000 - 1
        // console.log('startDate : '+this.dateRange[0] + ' ; ' + new Date(this.dateRange[0]).getMonth()+ ' - '+new Date(this.dateRange[0]).getDate())
        // console.log('endDate : '+this.dateRange[1] + ' ; ' + new Date(this.dateRange[1]).getMonth() + ' - '+new Date(this.dateRange[1]).getDate())
        // console.log('startDate : '+this.startDate + ' ; ' + new Date(this.startDate).getMonth()+ ' - '+new Date(this.startDate).getDate())
        // console.log(''ndDate : '+this.endDate + ' ; ' + new Date(this.endDate).getMonth() + ' - '+new Date(this.endDate).getDate())
        await this.reloadData()
    }

    async reloadData() {
        await this.getSeries()
        await this.getCourseDashboard()
    }

    getParams(): { [key: string]: any } {
        const param: { [key: string]: any } = {}
        if (this.startDate != 0) param.start = this.startDate
        if (this.endDate != 0) param.end = this.endDate
        param.tutor = this.selectedInstructor
        return param
    }

    async getCourseDashboard() {
        this.startLoading('body')

        this.courses = await this.getCourses()
        this.trialCourses = await this.getCourses(true)
        this.trialClassSelectedCourse = this.trialCourses.length ? this.trialCourses[0]._id : ''
        this.getOverviewData(this.getParams())
        this.getAcquisitionData(this.getParams())
        this.getTrialPerformance()
        this.getUserProfile(this.getParams())
        this.getRefundStatistics(this.getParams())

        this.retentionSelectedVersion = this.retentionVersionOptions.last()
        this.getRetention(this.getParams())

        this.getReferral(this.getParams())
        this.stopLoading()

        this.setQuery()
    }

    async getSeries() {
        await createRequest('/courses/series', 'get', {
            instructor: this.selectedInstructor
        }).send().then(async (res) => {
            this.seriesList = res.data.series
            this.retentionSelectedSeriesId = this.seriesList.length ? this.seriesList[0]._id : -1
            this.acquisitionSelectedSeries = -1
            this.trialClassSelectedCourse = ''
        })
    }

    getOverviewData(param) {
        createRequest(
            '/courses/dashboard/tutor/overview',
            'get',
            param
        ).send().then(async (res) => {
            this.overview = res.data.overview
        })
    }

    getAcquisitionData(param) {
        if (this.acquisitionSelectedSeries != -1) {
            param.series = this.acquisitionSelectedSeries
        }
        createRequest(
            '/courses/dashboard/tutor/application-source',
            'get',
            param
        ).send().then(async (res) => {
            this.acquisitionTableData = res.data.application_source
        })
    }

    getTrialPerformance() {
        let param: { [key: string]: any } = {}
        // param.tutor = this.selectedInstructor
        if (this.trialClassSelectedCourse != '') {
            param.course_id = this.trialClassSelectedCourse
        } else {
            return
        }

        createRequest(
            '/courses/dashboard/tutor/trial-performance',
            'get',
            param
        ).send().then(async (res) => {
            const vRes = await createRequest(`/course/${this.trialClassSelectedCourse}/class-ids/by-version`).send()
            this.trialClassVersions = vRes.data
            this.trialClassVersion = ''

            this.rawTrialClassTableData = res.data.trial_performance.map(tp => {
                return {
                    id: tp.id,
                    date: moment(tp.date).format('DD/MM'),
                    time: moment(tp.start).format('HH:mm') + ' - ' + moment(tp.end).format('HH:mm'),
                    applicants: tp.applicants,
                    attendance: tp.attendances || 0,
                    conversions_applied: tp.conversions_applied,
                    conversionAPercentage: this.percentageWithDP(tp.conversions_applied / (tp.attendances || tp.applicants), 2),
                    conversions_paid: tp.conversions_paid,
                    conversionPPercentage: this.percentageWithDP(tp.conversions_paid / (tp.attendances || tp.applicants), 2),
                    applied_c_courses: sortedObj(tp.applied_c_courses),
                    paid_c_courses: sortedObj(tp.paid_c_courses),
                }
            })
        })
    }

    get trialClassTableData() {
        const data = this.trialClassVersion > 0 ?
            this.rawTrialClassTableData.filter(row => this.trialClassVersions[this.trialClassVersion].includes(row.id)) :
            this.rawTrialClassTableData.slice()


        const totalApplicants = data.map(tp => tp.applicants).reduce((a, b) => a + b, 0)
        const conversionsBase = data.map(tp => tp.attendance || tp.applicants).reduce((a, b) => a + b, 0)
        const totalConversionsA = data.map(tp => tp.conversions_applied).reduce((a, b) => a + b, 0)
        const totalConversionsP = data.map(tp => tp.conversions_paid).reduce((a, b) => a + b, 0)

        const applied_c_courses: Dict<number> = data.reduce((dict, tp) => {
            for (const [cid, num] of Object.entries(tp.applied_c_courses))
                dict[cid] = (dict[cid] || 0) + num
            return dict
        }, {})
        const paid_c_courses: Dict<number> = data.reduce((dict, tp) => {
            for (const [cid, num] of Object.entries(tp.paid_c_courses))
                dict[cid] = (dict[cid] || 0) + num
            return dict
        }, {})

        data.push({
            id: -1,
            date: 'Total',
            time: '',
            applicants: totalApplicants,
            attendance: data.map(tp => tp.attendance).reduce((a, b) => a + b, 0),
            conversions_applied: totalConversionsA,
            conversionAPercentage: this.percentageWithDP(totalConversionsA / conversionsBase, 2),
            conversions_paid: totalConversionsP,
            conversionPPercentage: this.percentageWithDP(totalConversionsP / conversionsBase, 2),
            applied_c_courses: sortedObj(applied_c_courses),
            paid_c_courses: sortedObj(paid_c_courses)
        })
        return data
    }

    rentionPercentage(retentionData, index: number) {
        const denominator = retentionData.retention[(index - 1 < 0 ? 0 : index - 1)]
        if (denominator === 0) {
            return 0
        }
        return retentionData.retention[index] / denominator
    }

    percentageWithDP(value: number, dp: number): string {
        return String((Math.round(value * 100 * 100) / 100).toFixed(dp)) + '%'
    }

    getUserProfile(param) {
        if (this.userProfileSelectedCourse != '') {
            param['course_id'] = this.userProfileSelectedCourse
        }
        createRequest(
            '/courses/dashboard/tutor/user-profile',
            'get',
            param
        ).send().then(async (res) => {
            let resUserProfileDseData = this.pushResToDataAndLabels(res.data.user_profile.dse)
            this.userProfileDseYrData = resUserProfileDseData.data
            this.userProfileDseYrConfig = this.getPieConfigWithLabels(resUserProfileDseData.labels)

            let resUserProfileBandingData = this.pushResToDataAndLabels(res.data.user_profile.banding)
            this.userProfileBandingData = resUserProfileBandingData.data
            this.userProfileBandingConfig = this.getPieConfigWithLabels(resUserProfileBandingData.labels)
        })
    }

    getRefundStatistics(param) {
        param.course_id = this.refundSelectedCourse
        createRequest(
            '/courses/dashboard/tutor/refund-stat',
            'get',
            param
        ).send().then(async (res) => {
            this.refundRequests = res.data.requests.length
            this.refundTotal = res.data.total
            this.refundTableData = res.data.requests.map((request) => {
                const course = this.courses.find((c) => c._id === request.course_id)
                return {
                    date: moment(request.timestamp).format('DD/MM/YYYY HH:mm:ss'),
                    member_id: request.member_id,
                    name: request.display_name,
                    courseIdName: request.course_id + ' - ' + (course ? course.title : ''),
                    amount: '$' + String(request.amount)
                }
            })
        })
    }

    getRetention(param) {
        if (this.retentionSelectedSeriesId != -1) {
            param.series = this.retentionSelectedSeriesId
            param.version = this.retentionSelectedVersion
            createRequest(
                '/courses/dashboard/tutor/retention',
                'get',
                param
            ).send().then(async (res) => {
                this.retentionTableData = res.data.retention
                if (this.retentionTableData.length !== 0) {
                    this.retentionMaxNumber = Math.max(...this.retentionTableData.map(r => r.retention.length))
                } else {
                    this.retentionMaxNumber = 0
                }
            })
        }
    }

    getReferral(param) {
        createRequest(
            '/courses/dashboard/tutor/referral',
            'get',
            param
        ).send().then(async (res) => {
            this.seriesReferralTableData = res.data.series_referral
            this.seriesReferralSelectedIndex = 0
            this.seriesReferralOptions = res.data.series_referral.map(r => r.label)

            this.trialReferralTableData = res.data.trial_referral
            this.trialReferralSelectedIndex = 0
            this.trialReferralOptions = res.data.trial_referral.map(r => r.label)
        })
    }

    async getCourses(trial: boolean = false): Promise<Course[]> {
        const res = await createRequest(
            '/courses/admin',
            'get',
            {
                'instructor': this.selectedInstructor,
                'trial': trial,
                'status': 'published,pending,hidden,preordering,private',
                'limit': 0
            }
        ).send()
        return toInstanceForceArray(new Course(), res.data.courses)
    }

    pushResToDataAndLabels(res: any[]): { data: number[], labels: string[] } {
        let data: number[] = res.map((d) => d.value)
        let labels: string[] = res.map((d) => d.tag)
        return {
            data,
            labels
        }
    }

    getPieConfigWithLabels(labels: string[]) {
        let c = this.defaultPieChartConfig()
        labels.forEach(l => {
            c.labels!!.push(l)
        })
        return c
    }

    tableRowStyle({row, rowIndex}) {
        if (rowIndex == this.trialClassTableData.length - 1) {
            return ' font-size: medium; font-weight: bold;'
        }
    }

    tableHeaderColor({row, column, rowIndex, columnIndex}) {
        if (rowIndex === 0) {
            return 'background-color: #EDEDED; color: #000; font-weight: 500;'
        }
    }

    pieChartDataToCsvContent(pieChartData: { title: string; columns: string[]; labels: string[] | undefined; series: number[] }): string {
        let csvContent = ''
        let rows: string[] = []
        rows.push(pieChartData.title)
        rows.push(pieChartData.columns.join(','))
        for (const i in pieChartData.labels) {
            rows.push(pieChartData.labels[i] + ',' + pieChartData.series[i])
        }
        csvContent += rows.join('\n') + '\n\n'
        return csvContent
    }

    get overviewExportData() {
        let csvContent = ''

        // Overview.
        csvContent += 'OVERVIEW\n'
        csvContent += 'TOTAL,' + (this.overview.revenue.paid + this.overview.revenue.trial) + '\n'
        csvContent += 'REVENUE (PAID COURSE),' + (this.overview.revenue.paid) + '\n'
        csvContent += 'NO. OF PAID STUDENTS,' + (this.overview.students.paid_unique) + '\n'
        csvContent += 'REVENUE (TRIAL COURSE),' + (this.overview.revenue.trial) + '\n'
        csvContent += 'NO. OF TRIAL STUDENTS,' + (this.overview.students.trial_unique) + '\n\n'

        return csvContent
    }

    get acquisitionExportData() {
        let csvContent = ''

        let acquisitionRevenueRows: string[] = ['ACQUISITION']
        let acquisitionOrdersCountRows: string[] = ['ACQUISITION']
        acquisitionRevenueRows.push('MONTH,REVENUE (ORGANIC),REVENUE (TRIAL)')
        acquisitionOrdersCountRows.push('MONTH,NO. OF ORDERS (ORGANIC),NO. OF STUDENTS (TRIAL)')
        for (const monthData of this.acquisitionTableData) {
            acquisitionRevenueRows.push(monthData.tag + ',' + monthData.organic + ',' + monthData.trial)
            acquisitionOrdersCountRows.push(monthData.tag + ',' + monthData.organic_count + ',' + monthData.trial_count)
        }
        csvContent = csvContent + acquisitionRevenueRows.join('\n') + '\n\n'
        csvContent = csvContent + acquisitionOrdersCountRows.join('\n') + '\n\n'

        return csvContent
    }

    get trialClassPerformanceExportData() {
        let trialClassRows: string[] = ['TRIAL CLASS PERFORMANCE',
            [
                'Date',
                'Time',
                'No. of Applicants',
                'Attendance',
                'Conversions (applied, number)',
                ' Conversions (applied, percentage)',
                'Conversions (paid, number)',
                ' Conversions (paid, percentage)'
            ].join(',')
        ]
        trialClassRows.push(...this.trialClassTableData.map((rowData) => [
            rowData.date,
            rowData.time,
            rowData.applicants,
            rowData.attendance,
            rowData.conversions_applied,
            rowData.conversionAPercentage,
            rowData.conversions_paid,
            rowData.conversionPPercentage].join(',')))
        return trialClassRows.join('\n') + '\n\n'
    }

    get userProfileExportData(): string {
        let userProfileString = ''
        let userProfile = [
            {
                title: 'USER PROFILE',
                columns: ['DSE YEAR', 'NO. OF STUDENTS'],
                labels: this.userProfileDseYrConfig.labels,
                series: this.userProfileDseYrData
            },
            {
                title: 'USER PROFILE',
                columns: ['BANDING', 'NO. OF STUDENTS'],
                labels: this.userProfileBandingConfig.labels,
                series: this.userProfileBandingData
            }
        ]

        for (const chart of userProfile) {
            userProfileString += this.pieChartDataToCsvContent(chart)
        }
        return userProfileString
    }

    get refundStatsExportData() {
        let refundRows: string[] = ['REFUND STATISTICS', 'REFUND REQUESTS,REFUND TOTAL VALUE', (this.refundRequests + ',' + this.refundTotal), 'Refund Date,Member ID,Name,Course,Amount($)']
        refundRows.push(...this.refundTableData.map((rowData) => [rowData.date, rowData.member_id, rowData.name, rowData.courseIdName, rowData.amount.replace('$', '')].join(',')))
        return refundRows.join('\n') + '\n\n'
    }

    get paidCourseSeriesRetentionExportData() {
        return this.retentionTableData.map((retention, i) => {
            return [retention.retention.map((r) => String(r)), retention.retention.map((r, j) => this.percentageWithDP(r / retention.retention[(j - 1) >= 0 ? j - 1 : 0], 2))]
        }).reduce((prevVal, currentVal, i, array) => {
            let prepend = ('Volume ' + (i + 1)) + ',' + String(this.retentionTableData[i].total) + ','
            return prevVal.map((prev, j) => prev + prepend + currentVal[j].join(',') + '\n')
        }, ['', '']).map((table) => {
            return 'PAID COURSE SERIES RETENTION\nVolume,Total students,' + Array(this.retentionMaxNumber).fill(0).map((_, i) => String(i)).join(',') + '\n' + table
        }).join('\n') + '\n\n'
    }

    get seriesReferralExportData() {
        const cpy = this.seriesReferralTableData.slice()
        const all = cpy.shift()!
        cpy.push(all)

        const header = 'Series,' + cpy.map(col => col.label).join(',')
        const data = all.data.map(item => {
            const counts: number[] = []
            for (const col of cpy) {
                const found = col.data.find(d => d.item === item.item)
                counts.push(found ? found.count : 0)
            }
            return item.item + ',' + counts.join(',')
        })
        data.unshift(header)
        return 'SERIES REFERRAL\n' + data.join('\n')
    }

    get trialReferralExportData() {
        const cpy = this.trialReferralTableData.slice()
        const all = cpy.shift()!
        cpy.push(all)

        const header = 'Course,' + cpy.map(col => col.label).join(',')
        const data = all.data.map(item => {
            const counts: number[] = []
            for (const col of cpy) {
                const found = col.data.find(d => d.item === item.item)
                counts.push(found ? found.count : 0)
            }
            return item.item + ',' + counts.join(',')
        })
        data.unshift(header)
        return 'TRIAL COURSE REFERRAL\n' + data.join('\n')
    }

    downloadCsv(csvContent: string, filename: string) {
        // Export.
        const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    exportClicked() {
        let csvContent = ''

        // Overview.
        csvContent += this.overviewExportData

        // Acquisition.
        csvContent += this.acquisitionExportData

        // Trial class performance.
        csvContent += this.trialClassPerformanceExportData

        // User profile.
        csvContent += this.userProfileExportData

        // Refund statistics.
        csvContent += this.refundStatsExportData

        // Paid course series retention.
        csvContent += this.paidCourseSeriesRetentionExportData

        // Series referral.
        csvContent += this.seriesReferralExportData
        // Trial referral.
        csvContent += this.trialReferralExportData

        // Export.
        this.downloadCsv(csvContent, 'tutor_performance.csv')
    }

    @Watch('retentionSelectedSeriesId', {deep: false})
    retentionSelectedSeriesIdChanged() {
        this.retentionSelectedVersion = this.retentionVersionOptions.last()
        this.getRetention(this.getParams())
    }
}
